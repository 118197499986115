import { UpdateScheduler } from "./updateScheduler";
import { RefObject, useEffect, useRef } from "react";
import { useState } from "react";
import { CameraImageClipper } from "./imageClipper";
import { ScanGeometry } from "./scanGeometry";
import { previewHeight, previewWidth } from "./size";

interface Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ZXing: any;
}
declare let window: Window;

interface ByteArray {
  get: (index: number) => number;
  size: () => number;
}

export type QRCode = {
  text: string;
  rawBytes: ByteArray;
};

interface EmscriptenHeapAllocator {
  set: (array: Uint8ClampedArray, ptr: number) => void;
}

export type ZXing = {
  HEAPU8: EmscriptenHeapAllocator;
  _malloc: (size: number) => number;
  _free: (ptr: number) => void;
  readBarcodeFromPixmap: (
    bufferPtr: number,
    width: number,
    height: number,
    tryHarder: boolean,
    format: "QRCode"
  ) => QRCode;
};

function loadScript(scriptPath: string, onLoad: (result: ZXing) => void) {
  const scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  scriptElement.src = scriptPath;
  scriptElement.addEventListener("load", () => {
    window.ZXing().then(onLoad);
  });
  scriptElement.addEventListener("error", () => {
    alert(
      "ご利用のブラウザでは QR コードを読み込むことができません。最新版の Google Chrome もしくは Safari をお試しください。"
    );
  });
  document.body.appendChild(scriptElement);
}

function readQRCodeFromImageData(zxing: ZXing, imageData: ImageData) {
  // Canvas からキャプチャした画像に QRCode が含まれているか判定
  const binaryData = imageData.data;
  const buffer = zxing._malloc(binaryData.length);
  zxing.HEAPU8.set(binaryData, buffer);
  const result = zxing.readBarcodeFromPixmap(
    buffer,
    imageData.width,
    imageData.height,
    true,
    "QRCode"
  );
  zxing._free(buffer);

  return result;
}

const getPreviewGeometry = (videoWidth: number, videoHeight: number) => ({
  width: previewWidth,
  height: previewHeight,
  x: Math.trunc((videoWidth - previewWidth) / 2),
  y: Math.trunc((videoHeight - previewHeight) / 2),
});

/*
  ▼ どう動いているかの解説
  このQRコードリーダーは次のように動いています。
  1. getUserMedia を使って video タグを通してカメラの画像を取得
  2. canvas タグにカメラから取得した画像をトリミングして描画
  3. その画像を ZXing に渡してQRコードを判定
*/
export function useQRCodeReader(
  videoRef: RefObject<HTMLVideoElement>,
  canvasRef: RefObject<HTMLCanvasElement>,
  onRead: (result: QRCode) => void
) {
  const [zxing, setZxing] = useState<ZXing | null>(null);
  const scheduler = useRef(new UpdateScheduler());

  const updatePreview = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (!canvas || !video) return;

    const context = canvas.getContext("2d");

    if (!context) {
      return;
    }

    const geometry = getPreviewGeometry(video.videoWidth, video.videoHeight);

    context.drawImage(
      video,
      geometry.x, // トリミングする開始座標の x
      geometry.y, // トリミングする開始座標の y
      geometry.width, // トリミングする幅
      geometry.height, // トリミングする高さ
      0, // 描画する座標の x
      0, // 描画する座標の y
      geometry.width,
      geometry.height
    );
  };

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.width = previewWidth;
      canvasRef.current.height = previewHeight;
    }
  }, [canvasRef]);

  useEffect(() => {
    if (!zxing && window.ZXing) {
      window.ZXing().then(setZxing);
    }

    loadScript(
      process.env.PUBLIC_URL + "/qrcode/fixed/zxing_reader.js",
      setZxing
    );
  }, []);

  useEffect(() => {
    scheduler.current.start(() => {
      const video = videoRef.current;
      if (!video || !zxing) {
        return;
      }

      updatePreview();

      const imageData = new CameraImageClipper(
        video,
        new ScanGeometry(video.videoWidth, video.videoHeight)
      ).clip();
      if (!imageData) {
        return;
      }

      const result = readQRCodeFromImageData(zxing, imageData);

      if (!result.text.length) {
        return null;
      }

      onRead({ text: result.text, rawBytes: result.rawBytes });
    });

    return () => scheduler.current.stop();
  }, [zxing]);
}
