import React, { createContext, useContext, useState } from "react";

type Patient = string;

type PatientContextType = {
  patient: Patient | null;
  setPatient: React.Dispatch<React.SetStateAction<Patient | null>>;
};

const PatientContext = createContext({} as PatientContextType);

type Props = { children: React.ReactNode };

export const PatientContextProvider: React.VFC<Props> = ({ children }) => {
  const [patient, setPatient] = useState<Patient | null>(null);

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>
      {children}
    </PatientContext.Provider>
  );
};

export const usePatient = () => useContext(PatientContext);
