import * as React from "react";
import { LoginPage } from "../../pages/LoginPage";
import { useAuth } from "../../service/auth/AuthProvider";

export function Authenticator(
  // eslint-disable-next-line
  props: { component: React.ComponentType; pageProps: any }
) {
  const { signedIn } = useAuth();

  if (signedIn) {
    return <props.component {...props.pageProps} />;
  }

  return <LoginPage />;
}
