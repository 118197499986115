import { useHistory } from "react-router-dom";
import styles from "./Header.module.scss";

type Props = {
  hasBackButton?: boolean;
  title: string;
};

export const Header: React.VFC<Props> = (props) => {
  const history = useHistory();

  return (
    <header className={styles.sec_header01}>
      {props.hasBackButton && (
        <button
          className={styles.sec_header01_back}
          onClick={() => history.goBack()}
        ></button>
      )}
      <h1 className={styles.sec_header01_text}>{props.title}</h1>
    </header>
  );
};
