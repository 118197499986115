import React, { createContext, useContext, useEffect, useState } from "react";
import { useCallback } from "react";
import { Pharmacy_app_me_response } from "../api/@types";
import { getItemAndParse, KEYS, setItem } from "../service/localStorage";

export type Pharmacy = Pharmacy_app_me_response["pharmacies"][number];

type PharmacyContextType = {
  pharmacy: Pharmacy | null;
  setPharmacy: (pharmacy: Pharmacy | null) => void;
};

const PharmacyContext = createContext({} as PharmacyContextType);

type Props = { children: React.ReactNode };

export const PharmacyContextProvider: React.VFC<Props> = ({ children }) => {
  const [pharmacy, setPharmacy] = useState<Pharmacy | null>(null);

  useEffect(() => {
    // 初期表示時に localStorage に選択した薬局情報があれば復元
    const savedPharmacy = getItemAndParse(
      KEYS.SELECTED_PHARMACY
    ) as Pharmacy | null;

    if (savedPharmacy) {
      setPharmacy(savedPharmacy);
    }
  }, []);

  const _setPharmacy = useCallback(
    (newPharmacy: Pharmacy | null) => {
      setItem(KEYS.SELECTED_PHARMACY, newPharmacy);
      setPharmacy(newPharmacy);
    },
    [setPharmacy]
  );

  return (
    <PharmacyContext.Provider value={{ pharmacy, setPharmacy: _setPharmacy }}>
      {children}
    </PharmacyContext.Provider>
  );
};

export const usePharmacy = () => useContext(PharmacyContext);
