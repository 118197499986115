import { LoadingRing } from "../components/LoadingRing";
import { useModal } from "./useModal";

export default function useLoadingOverlay() {
  const { showModal, closeModal } = useModal();

  const showLoadingOverlay = () => {
    showModal({ content: <LoadingRing /> });
  };

  return {
    showLoadingOverlay,
    closeLoadingOverlay: closeModal,
  };
}
