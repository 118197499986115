import { Header } from "../../../components/Header";
import { ErrorPage } from "../components/ErrorPage";

export const NotFoundPage: React.VFC = () => {
  return (
    <div>
      <Header title="エラー" />
      <ErrorPage
        title="ページが見つかりません"
        description={
          <>
            一時的にアクセスできないか、
            <br />
            削除された可能性があります。
          </>
        }
      />
    </div>
  );
};
