import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const SCRIPT1_NAME = "gtag";
const SCRIPT2_NAME = "gtagScript";

export const initializeGA = (googleAnalyticsId: string | undefined): void => {
  // トラッキングID or 測定IDが空の場合は終了
  if (!googleAnalyticsId) return;

  // scriptが既にある場合は一度削除
  document.getElementById(SCRIPT1_NAME)?.remove();
  document.getElementById(SCRIPT2_NAME)?.remove();

  // gtag.jsをheadタグに埋め込み
  const script1 = document.createElement("script");
  script1.id = SCRIPT1_NAME;
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
  script1.async = true;
  document.head.appendChild(script1);

  // 実行用scriptをheadタグに埋め込み
  const script2 = document.createElement("script");
  script2.id = SCRIPT2_NAME;
  script2.text = `window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', '${googleAnalyticsId}');`;
  document.head.appendChild(script2);
};

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_path: string }
    ) => void;
  }
}

// トラッキング用関数
export const useTracking = (googleAnalyticsId: string | undefined): void => {
  if (!googleAnalyticsId) return;

  const history = useHistory();

  if (!history) return;

  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!window.gtag) return;
      if (!googleAnalyticsId) return;
      window.gtag("config", googleAnalyticsId, {
        page_path: location.pathname,
      });
    });

    return unlisten;
  }, [googleAnalyticsId, history]);
};
