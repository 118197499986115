export const configureV1 = {
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_CONFIG_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_CONFIG_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AMPLIFY_CONFIG_USER_POOL_WEB_CLIENT_ID,
  },
};

export const configureV2 = {
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_CONFIG_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_CONFIG_USER_POOL_ID_V2,
    userPoolWebClientId:
      process.env.REACT_APP_AMPLIFY_CONFIG_USER_POOL_WEB_CLIENT_ID_V2,
  },
};
