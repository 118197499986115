import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import api from "../../api/pharmacy_app/prescriptions/check/$api";
import { Header } from "../../components/Header";
import { usePatients } from "../../hooks/usePatients";
import { apiClient } from "../../lib/apiClient";
import styles from "./QRcodePage.module.scss";
import { useQRCodeReader } from "./hooks/useQRCodeReader";
import { useCamera } from "./hooks/useCamera";
import { useQRCode } from "./hooks/useQRCode";
import { useMemo } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import { useRawPrescription } from "../../hooks/useRawPrescription";
import useLoadingOverlay from "../../hooks/useLoadingOverlay";
import { usePharmacy } from "../../hooks/usePharmacy";
import { datadogLogs } from "@datadog/browser-logs";

export const QRCodePage = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const messageRef = useRef<HTMLParagraphElement>(null);
  const { showLoadingOverlay, closeLoadingOverlay } = useLoadingOverlay();

  const history = useHistory();
  const { setPatients } = usePatients();
  const { setRawPrescription } = useRawPrescription();

  const { pharmacy } = usePharmacy();

  if (pharmacy === null) {
    history.replace("/pharmacies");
    return null;
  }

  const { stopCamera } = useCamera(videoRef);

  const onSubmit = () => {
    const text = Object.values(contentsByIndex).join("");

    showLoadingOverlay();
    api(apiClient)
      .post({
        body: { raw_prescription: text },
      })
      .then((result) => {
        setPatients(result.body.patients);
        setRawPrescription(text);
        stopCamera();
        history.push("/patients");
      })
      .catch((e) => {
        datadogLogs.logger.error(JSON.stringify(e));
        stopCamera();
        if (e.response.status >= 500 && e.response.status < 600) {
          history.push("/error-500");
        } else {
          history.push("/qrcode-error");
        }
      })
      .finally(() => {
        closeLoadingOverlay();
      });
  };

  const { onReadQRCode, contentsByIndex, isStructured, total } =
    useQRCode(onSubmit);

  useQRCodeReader(videoRef, canvasRef, onReadQRCode);

  const headerText = useMemo(() => {
    if (total > 1) {
      return `${total}個中${
        Object.keys(contentsByIndex).length
      }個を読み込みました`;
    }
    return "QRコードを１つずつ枠内に写して下さい。";
  }, [isStructured, total, contentsByIndex]);

  const prevHeaderText = usePrevious(headerText);
  useEffect(() => {
    if (prevHeaderText !== headerText && messageRef.current) {
      messageRef.current.classList.add(styles.animate);
      setTimeout(() => {
        if (messageRef.current) {
          messageRef.current.classList.remove(styles.animate);
        }
      }, 1000);
    }
  }, [prevHeaderText, headerText]);

  return (
    <div>
      <Header hasBackButton title="お薬手帳QRコード読み込み" />

      <main>
        <section className={styles.sec_video01}>
          <div className={styles.sec_video01_box}>
            <p className={styles.sec_video01_text}>
              <span ref={messageRef}>{headerText}</span>
            </p>

            <video
              ref={videoRef}
              style={{ position: "absolute" }}
              autoPlay
              playsInline
              muted
              width="1"
              height="1"
            />
            <canvas
              className={styles.sec_video01_video}
              ref={canvasRef}
            ></canvas>
          </div>
        </section>
      </main>
    </div>
  );
};
