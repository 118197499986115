import { configureV1, configureV2 } from "../../config/cognito.config";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "aws-amplify";

export const signIn: (
  username: string,
  password: string
) => Promise<unknown> = (username, password) => {
  Amplify.configure(configureV2);
  return Auth.signIn(username, password)
    .then(() => {
      return Promise.resolve;
    })
    .catch(() => {
      Amplify.configure(configureV1);
      return Auth.signIn(username, password);
    });
};

export const signedIn: () => Promise<boolean> = async () => {
  return Auth.currentAuthenticatedUser()
    .then(() => true)
    .catch(() => false);
};

export const signOut: () => Promise<unknown> = () => {
  return Auth.signOut();
};

export const getToken: () => Promise<string | undefined> = () => {
  return Auth.currentSession()
    .then((session) => session.getIdToken().getJwtToken())
    .catch(() => undefined);
};
