import { previewHeight } from "./useQRCodeReader/size";
import { RefObject, useCallback, useEffect } from "react";
import * as Sentry from "@sentry/browser";

function detectMac() {
  // ref. https://iwb.jp/ipad-safari-javascript-useragent-is-not-ipad/

  return navigator.userAgent.match(/Macintosh/i) && !("ontouchend" in document);
}

const videoBaseConstraints = {
  focusMode: "continuous",
  exposureMode: "continuous",
  whiteBalanceMode: "continuous",
  width: {
    ideal: 1000,
  },
  height: {
    min: previewHeight,
  },
};

function videoOption(reverseFacingMode?: boolean): MediaStreamConstraints {
  // PCの場合はリアカメラが無い
  const useRear = reverseFacingMode ? !detectMac() : detectMac();

  const options: MediaStreamConstraints = useRear
    ? {
        audio: false,
        video: {
          ...videoBaseConstraints,
        },
      }
    : {
        audio: false,
        video: {
          ...videoBaseConstraints,
          facingMode: { exact: "environment" },
        },
      };

  return options;
}

export const useCamera = (videoRef: RefObject<HTMLVideoElement>) => {
  // カメラを起動して video タグに映す
  useEffect(() => {
    const video = videoRef.current;
    if (video && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(videoOption())
        .then(function (stream) {
          video.srcObject = stream;
        })
        .catch(() => {
          // カメラがなくてエラーが起きる場合があるので、使うカメラを切り替えてリトライする。
          navigator.mediaDevices
            .getUserMedia(videoOption(true))
            .then(function (stream) {
              video.srcObject = stream;
            })
            .catch((err) => {
              Sentry.captureMessage(err, Sentry.Severity.Error);
            });
        });
    }
  }, []);

  const stopCamera = useCallback(() => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      stream.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  }, []);

  return { stopCamera };
};
