import { Pharmacy_app_me_pharmacies } from "../../../../api/@types";
import styles from "./PharmaciesList.module.scss";

type Props = {
  onClick: (pharmacy: Pharmacy_app_me_pharmacies) => void;
  pharmacies: Pharmacy_app_me_pharmacies[];
};

export const PharmaciesList: React.VFC<Props> = (props) => {
  return (
    <ul className={styles.glb_list01}>
      {props.pharmacies.map((pharmacy) => (
        <li key={pharmacy.id}>
          <a
            onClick={() => props.onClick(pharmacy)}
            className={styles.glb_list01_text}
          >
            {pharmacy.name}
          </a>
        </li>
      ))}
    </ul>
  );
};
