import React, { createContext, useContext, useState } from "react";

type RawPrescription = string;

type RawPrescriptionContextType = {
  rawPrescription: RawPrescription | null;
  setRawPrescription: React.Dispatch<
    React.SetStateAction<RawPrescription | null>
  >;
};

const RawPrescriptionContext = createContext({} as RawPrescriptionContextType);

type Props = { children: React.ReactNode };

export const RawPrescriptionContextProvider: React.VFC<Props> = ({
  children,
}) => {
  const [rawPrescription, setRawPrescription] =
    useState<RawPrescription | null>(null);

  return (
    <RawPrescriptionContext.Provider
      value={{ rawPrescription, setRawPrescription }}
    >
      {children}
    </RawPrescriptionContext.Provider>
  );
};

export const useRawPrescription = () => useContext(RawPrescriptionContext);
