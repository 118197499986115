import * as React from "react";
import styles from "./modal.module.scss";

type Props = {
  showModal: boolean;
  onClose: () => void;
  contents: React.ReactNode;
};

/*
 * 次の二つを前提としているので、それ以外の要求が出てきた時は Props 生やすなりして頑張ってほしい
 * - モーダル外の領域をクリックしたら閉じる
 * - モーダルの中身は常に上下左右中央に表示される
 */
export const Modal: React.VFC<Props> = ({ showModal, onClose, contents }) => {
  return (
    <div
      className={`${styles.overlay} ${showModal ? styles.show : ""}`}
      onClick={onClose}
    >
      <div onClick={(e) => e.stopPropagation()}>{contents}</div>
    </div>
  );
};
