import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./reset.css";
import "./layout.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { datadogLogs } from "@datadog/browser-logs";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { getItemAndParse, KEYS } from "./service/localStorage";
import { Pharmacy } from "./hooks/usePharmacy";

Sentry.init({
  dsn: "https://c94a402f5171427f9e89f0799c1742a7@o476423.ingest.sentry.io/5836620",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_API_URL as string],
    }),
  ],
  environment: process.env.REACT_APP_STAGE,
  tracesSampleRate: 0.01,
});

datadogLogs.init({
  clientToken: "pubb576283ac7dd5de7e5b7aa563e29d631",
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: `pm-pharmacy-app-${process.env.REACT_APP_STAGE}`,
});

(async () => {
  const savedPharmacy = getItemAndParse(
    KEYS.SELECTED_PHARMACY
  ) as Pharmacy | null;

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || "",
    user: {
      key: savedPharmacy ? savedPharmacy.id : "",
    },
  });

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
