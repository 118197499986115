import { Header } from "../../../components/Header";
import { ErrorPage } from "../components/ErrorPage";

export const InternalServerErrorPage: React.VFC = () => {
  return (
    <div>
      <Header title="エラー" />
      <ErrorPage
        title="技術的な問題が発生しています"
        description={
          <>
            現在、問題解決に取り組んでおります。
            <br />
            しばらくしてからもう一度お試しください。
          </>
        }
      />
    </div>
  );
};
