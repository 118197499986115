import { ScanGeometry } from "./scanGeometry";

export class CameraImageClipper {
  readonly canvas = document.createElement("canvas");

  clip(): ImageData | null {
    const context = this.canvas.getContext("2d");
    if (!context) {
      return null;
    }

    const { x, y, width, height } = this.geometry;
    context.drawImage(this.video, x, y, width, height, 0, 0, width, height);

    return context.getImageData(0, 0, width, height);
  }

  constructor(private video: HTMLVideoElement, private geometry: ScanGeometry) {
    this.canvas.width = this.geometry.width;
    this.canvas.height = this.geometry.height;
  }
}
