import React, { createContext, useContext, useState } from "react";
import { Pharmacy_app_check_patient_prescription_response } from "../api/@types";

type Patients = Pharmacy_app_check_patient_prescription_response["patients"];

type PatientsContextType = {
  patients: Patients;
  setPatients: React.Dispatch<React.SetStateAction<Patients>>;
};

const PatientsContext = createContext({} as PatientsContextType);

type Props = { children: React.ReactNode };

export const PatientsContextProvider: React.VFC<Props> = ({ children }) => {
  const [patients, setPatients] = useState<Patients>([]);

  return (
    <PatientsContext.Provider value={{ patients, setPatients }}>
      {children}
    </PatientsContext.Provider>
  );
};

export const usePatients = () => useContext(PatientsContext);
