import React from "react";
import styles from "./TextField.module.scss";

type Props = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  maxLength: number;
  type: "text" | "password";
};

export const TextField: React.VFC<Props> = (props) => {
  return (
    <input
      type={props.type}
      className={styles.input}
      onChange={props.onChange}
      placeholder={props.placeholder}
      maxLength={props.maxLength}
    />
  );
};
