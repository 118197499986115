import * as React from "react";
import { Modal } from "../components/Modal";

export type ModalProps = {
  showModal: (props: {
    content: React.ReactNode;
    closeOnClickOverlay?: boolean;
  }) => void;
  closeModal: () => void;
};
const ModalContext = React.createContext<ModalProps>({
  showModal: () => {
    return;
  },
  closeModal: () => {
    return;
  },
});

export const ModalProvider: React.FC = (props) => {
  const [contentNode, setContentNode] = React.useState<React.ReactNode | null>(
    null
  );
  const [show, setShowModal] = React.useState(false);
  const [closeOnClickOverlay, setCloseOnClickOverlay] = React.useState(false);

  const showModal = React.useCallback(
    (props: { content: React.ReactNode; closeOnClickOverlay?: boolean }) => {
      setContentNode(props.content);
      setShowModal(true);
      setCloseOnClickOverlay(!!props.closeOnClickOverlay);
    },
    []
  );

  const closeModal = React.useCallback(() => {
    setContentNode(null);
    setShowModal(false);
    setCloseOnClickOverlay(false);
  }, []);

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {props.children}
      <Modal
        showModal={show}
        contents={contentNode}
        onClose={
          closeOnClickOverlay
            ? closeModal
            : () => {
                return;
              }
        }
      />
    </ModalContext.Provider>
  );
};
export const useModal = () => React.useContext(ModalContext);
