import { scanWidth, scanHeight } from "./size";

export class ScanGeometry {
  readonly width = scanWidth;
  readonly height = scanHeight;
  readonly x = Math.trunc((this.videoWidth - this.width) / 2);
  readonly y = Math.trunc((this.videoHeight - this.height) / 2);

  constructor(private videoWidth: number, private videoHeight: number) {}
}
