import { Link } from "react-router-dom";
import styles from "./LinkButton.module.scss";

type Props = {
  url: string;
  children: React.ReactNode;
};

export const LinkButton: React.VFC<Props> = (props) => {
  return (
    <Link className={styles.glb_btn01} to={props.url}>
      {props.children}
    </Link>
  );
};
