import { useEffect, useRef, useState } from "react";
import { QRCode } from "./useQRCodeReader";

function getQRCodePropsFor911Record(originalText: string) {
  try {
    const records = originalText.split("\r\n");
    records.pop();
    const lastRecord = records.slice(-1)[0];

    if (lastRecord.indexOf("911") === 0) {
      const columns = lastRecord.split(",");
      const parity = columns[1];
      const total = +columns[2];
      const offset = +columns[3] - 1;
      records.pop(); // 911レコードは結合するときに不要なので削除する
      if (offset !== 0) {
        records.shift(); // 2個目以降のQRコードはJAHISのヘッダーを削除する
      }
      const text = records.join("\r\n") + "\r\n";

      return { offset, total, parity, text };
    }
  } catch (e) {
    return;
  }
  return null;
}

export function useQRCode(onSubmit: () => void) {
  const submitted = useRef(false);
  const [isStructured, setIsStructured] = useState(false);
  const [contentsByIndex, setContentsByIndex] = useState<{
    [key: number]: string;
  }>({});
  const [total, setTotal] = useState(0);
  const firstReadParity = useRef<string | undefined>();

  useEffect(() => {
    if (submitted.current) return;

    // 分割 QR コードで読み込んだ個数が総数と一緒になった時か、単独 QR で一個でも読み取られた時に送信を実行する
    if (total > 0 && total === Object.keys(contentsByIndex).length) {
      onSubmit();
      submitted.current = true;
    }
    if (total === 0 && Object.keys(contentsByIndex).length > 0) {
      onSubmit();
      submitted.current = true;
    }
  }, [isStructured, total, contentsByIndex, onSubmit]);

  const onReadQRCode = (result: QRCode) => {
    // JAHIS 準拠の QR コードの場合
    // 参考: https://docs.google.com/document/d/1DulxqSfD5LtDWImneYHM81NDVXu9SxzrmcCJLYJ7U2w/edit#
    const _911Record = getQRCodePropsFor911Record(result.text);
    if (_911Record) {
      if (!firstReadParity.current) {
        // 1つ目に読み込んだQRコードのparityを保持する
        firstReadParity.current = _911Record.parity;
      } else if (firstReadParity.current !== _911Record.parity) {
        // 最初に読んだQRコードと別のQRコードを読み込もうとしたら何もしない
        return;
      }
      setTotal(_911Record.total);
      setContentsByIndex((_contents) => ({
        ..._contents,
        [_911Record.offset]: _911Record.text,
      }));
      return;
    }

    // デンソー準拠の QR コードの場合

    // 最初のバイトが3N(Nは何個目の QR コードかを示す)だったら連結 QR コード
    // 論理積で1の位を0にして比較している
    const firstByte = result.rawBytes.get(0);
    const isStructured = (firstByte & 0xf0) === 0x30;

    if (isStructured) {
      setIsStructured(isStructured);
      // QR コードの総数を取得
      const secondByte = result.rawBytes.get(1);

      // 総数は2個目のバイトの上4bit
      const total = (secondByte >> 4) + 1;
      setTotal(total);

      // 何個目かを表しているのは最初のバイトの 0x3n の n の部分
      const offset = firstByte & 0x0f;
      setContentsByIndex((_contents) => ({
        ..._contents,
        [offset]: result.text,
      }));
      return;
    }

    setContentsByIndex((_contents) => ({
      ..._contents,
      [0]: result.text,
    }));
  };

  return { onReadQRCode, isStructured, contentsByIndex, total };
}
