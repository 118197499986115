export class UpdateScheduler {
  private timer: number | null = null;
  private running = false;

  startTimer(tick: () => void): void {
    this.timer = requestAnimationFrame(() => {
      tick();
      this.timer = null;
    });
  }

  start(task: () => void): void {
    this.running = true;
    const tick = () => {
      task();
      if (this.running) {
        this.startTimer(tick);
      }
    };
    this.startTimer(tick);
  }

  stop(): void {
    this.running = false;
    if (this.timer !== null) {
      cancelAnimationFrame(this.timer);
      this.timer = null;
    }
  }

  constructor() {
    return;
  }
}
